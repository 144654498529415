import 'react-datepicker/dist/react-datepicker.css'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import '@/theme/style.scss'

import * as Sentry from '@sentry/react'
import { Fragment, StrictMode } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import '@/i18n/I18n.ts'
import { routes } from '@/routes.tsx'
import { ClientConfigProvider, bootstrapClientConfig } from '@/hooks/useClientConfig.tsx'
import { AuthContextProvider } from '@/hooks/useClientAuth.tsx'
import { ClientConfigType } from '@/hooks/ClientConfig.type.ts'
import { adjustConfig, switchMode, switchNav } from '@/hooks/clientConfigHelper.ts'
import { envConfig, envConfigUrl } from './env/EnvConfig'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createRoot } from 'react-dom/client'
import ToastEmitter from '@/layout/components/toasts/ToastEmitter.tsx'

// Configs
const baseStorageKey = 'fe.react'
const clientConfigStorageKey = baseStorageKey + '.client-config'
const enableStrictMode = false

// Bootstrapping
bootstrapClientConfig((config: ClientConfigType) => {
    console.log('(bootstrap)', 'strict mode:', enableStrictMode)

    config = adjustConfig(config)
    switchMode(config.mode)
    switchNav(config.nav)
    return config
}, clientConfigStorageKey)

fetch(envConfigUrl)
    .then(response => {
        if (!response?.ok) {
            throw new Error(response?.statusText)
        }
        return response.json()
    })
    .then(data => {
        envConfig.config = data
        setTimeout(() => {
            console.log('(startup)', 'version:', data.version, 'env:', data.env)
            // Sentry

            if (data.monitoring) {
                console.log('(startup)', 'sentry:', 'active')
                Sentry.init({
                    dsn: data.monitoring.dsn,
                    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
                    release: 'fe.web.client@' + data.version,
                    environment: data.env ?? 'dev',
                    // Tracing
                    tracesSampleRate: 1.0, //  Capture 100% of the transactions
                    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                    tracePropagationTargets: ['localhost', /^https:\/\/api\.staging\.cool-expert\.cloud/],
                    // Session Replay
                    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
                    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
                })
            }
            const queryClient = new QueryClient({
                defaultOptions: { queries: { retry: false } }
            })
            // Create react app
            let Wrapper = Fragment //fallback in case you dont want to wrap your components
            if (enableStrictMode) {
                Wrapper = StrictMode
            }

            createRoot(document.getElementById('root')!).render(
                <Wrapper>
                    <QueryClientProvider client={queryClient}>
                        <AuthContextProvider>
                            <ClientConfigProvider storageKey={clientConfigStorageKey}>
                                <RouterProvider
                                    router={createBrowserRouter(routes)}
                                    future={{ v7_startTransition: true }}
                                    fallbackElement={<span>Loading...</span>}
                                />
                                <ToastEmitter />
                            </ClientConfigProvider>
                        </AuthContextProvider>
                    </QueryClientProvider>
                </Wrapper>
            )
        }, 0)
    })
    .catch(error => {
        console.error('HANDLER', error)
    })
