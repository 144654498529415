import { ApiResponseError } from '@/api/requests.type.ts'
import { envConfig } from '@/env/EnvConfig.ts'
import { clientAuthTokenStore } from '@/hooks/ClientAuthTokenStore.ts'
import { TokenDataType, TokenExpiryDataType } from '@/hooks/ClientAuth.type.ts'
import { mergeDeep } from '@/utils/mergeDeep.ts'
import i18next from '@/i18n/I18n.ts'
import i18n, { fallbackLanguage } from '@/i18n/I18n.ts'

// Constant refresh route
const API_URL_AUTH_REFRESH = '/auth/refresh'
// Error codes that trigger a logout
const AUTH_ERRORS = [
    'auth_no_matching_headers',
    'auth_invalid_credentials',
    'auth_missing_token',
    'auth_invalid_token',
    'auth_not_authenticated',
    'auth_refresh_failed',
    'auth_server_failure'
]
// Define constants for header values
const CONTENT_TYPE_HEADER = 'Content-Type'
const ACCEPT_LANGUAGE_HEADER = 'accept-language'
const AUTHORIZATION_HEADER = 'Authorization'
const CONTENT_TYPE_JSON = 'application/json'

export function isApiResponseError(e: any): e is ApiResponseError {
    return !!e && !!e.error && typeof e.error.code === 'string' && typeof e.error.timestamp === 'string'
}

export function createError(code: string, message?: string, subsys?: string): ApiResponseError {
    return {
        error: {
            code: code,
            message: message ? message : undefined,
            timestamp: new Date().toISOString(),
            subsys: 'fe:' + (subsys ? subsys : 'webclient')
        }
    }
}

export function handleError(e: any): ApiResponseError {
    if (e instanceof TypeError) {
        return createError('network_unavailable', i18next.t('shell.common.frontend_errors.network_unavailable'))
    } else if (e && isApiResponseError(e)) {
        return e
    } else {
        return createError('invalid_error_response', i18next.t('shell.common.frontend_errors.invalid_error_response'))
    }
}

// Extract function to get default headers
function getDefaultHeaders(token: string) {
    return {
        [CONTENT_TYPE_HEADER]: CONTENT_TYPE_JSON,
        [ACCEPT_LANGUAGE_HEADER]: i18n.resolvedLanguage ?? fallbackLanguage,
        [AUTHORIZATION_HEADER]: `Bearer ${token}`
    }
}

export const doRequest = async (url: string, opts: RequestInit | undefined) => {
    try {
        const response = await fetch(url, opts)
        const json = await response.json()

        if (!response.ok && json.error.code === 'auth_expired_token') {
            return ['expired', json]
        }
        if (!response.ok && AUTH_ERRORS.indexOf(json.error.code) >= 0) {
            return ['auth_error', json]
        }
        if (!response.ok) {
            return ['error', json]
        }
        return ['success', json]
    } catch (e) {
        if (e instanceof DOMException && e.name === 'AbortError') {
            // Either we have an AbortError and the signal throws...
            return ['abort', e]
        }
        return ['error', e]
    }
}

// Extract function to handle token refresh logic
export const refreshAuthToken = async (): Promise<any> => {
    const refreshUrl = `${envConfig.config.apiUrl}${API_URL_AUTH_REFRESH}`
    return await doRequest(refreshUrl, {
        method: 'GET',
        headers: getDefaultHeaders(clientAuthTokenStore.getRefreshToken()!)
    })
}

export const apiRequest = async (
    url: string,
    opts: RequestInit | undefined,
    onUpdateTokens?: (tokens: TokenDataType, tokens_expiry: TokenExpiryDataType) => void,
    onAuthError?: (type: 'expired' | 'auth_error') => void
) => {
    const initialOpts = mergeDeep({}, opts)
    initialOpts.headers = new Headers(
        mergeDeep(getDefaultHeaders(clientAuthTokenStore.getAccessToken()!), opts?.headers)
    )
    let response = await doRequest(url, initialOpts)
    if (response[0] === 'success') {
        return response[1]
    }
    if (response[0] === 'expired') {
        response = await refreshAuthToken()
        if (response[0] === 'success') {
            onUpdateTokens?.(
                response[1].data.tokens as TokenDataType,
                response[1].data.tokens_expiry as TokenExpiryDataType
            )
            initialOpts.headers.set(AUTHORIZATION_HEADER, `Bearer ${clientAuthTokenStore.getAccessToken()!}`)
            response = await doRequest(url, initialOpts)
            if (response[0] === 'success') {
                return response[1]
            }
        }
    }
    if (response[0] === 'expired' || response[0] === 'auth_error') {
        onAuthError?.(response[0])
    }
    // Failure handling
    const errorResponse = handleError(response[1])
    throw errorResponse as ApiResponseError
}

export const apiFetch = async <T>(
    url: string,
    opts: RequestInit | undefined,
    successCb: (data: T) => void,
    errorCb: (err: ApiResponseError) => void,
    prepareCb?: () => void,
    finalizeCb?: () => void
): Promise<void> => {
    try {
        if (prepareCb) prepareCb()
        const response = await fetch(url, opts)
        const json = await response.json()

        if (!response.ok) {
            throw json
        }
        successCb(json)
    } catch (e) {
        const errorResponse = handleError(e)
        errorCb(errorResponse)
    }
    if (finalizeCb) finalizeCb()
}
