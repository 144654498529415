import {
    UserAbilitiesActionType,
    UserAbilitiesType,
    UserAbilitiesObjectType,
    UserAbilitiesPath
} from '@/utils/abilityCheck.type.ts'

export const abilityCheck = (
    abilities: UserAbilitiesType,
    abilityPath: UserAbilitiesPath,
    abilityAction: UserAbilitiesActionType = '*'
) => {
    if (abilityPath === null || abilityPath === undefined) {
        // generic 'allow all' - no ability Path given
        return true
    }
    if (abilityPath == '*') {
        // simple check for login
        return !!abilities
    }
    if (abilityAction.length != 1) {
        console.error('length invalid')
        return false
    }
    if (!abilities) {
        // shortcut - no provided abilities
        return false
    } else if (abilities == '*') {
        // shortcut - super admin abilities
        return true
    }
    const isAbilityObjectType = (x: unknown): x is UserAbilitiesObjectType => typeof x === 'object'
    const val = abilityPath
        .split('.')
        .reduce<
            UserAbilitiesObjectType | undefined
        >((obj: UserAbilitiesObjectType | undefined, i): UserAbilitiesObjectType | undefined => {
            if (obj && isAbilityObjectType(obj)) {
                return (obj as UserAbilitiesObjectType)[i] as UserAbilitiesObjectType
            }
        }, abilities)

    if (!val) {
        // Ability path not found in abilities
        return false
    }
    if (typeof (val as unknown) != 'string') {
        console.error('invalid ability value')
        return false
    }

    const actions = val as unknown as string

    if (actions == '*') return true

    return actions.includes(abilityAction)
}
