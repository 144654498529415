import { ClientConfigModeType, ClientConfigNavType, ClientConfigType } from '@/hooks/ClientConfig.type.ts'

export function isDarkMode(mode: ClientConfigModeType): boolean {
    return mode === 'dark' || (mode === 'auto' && window.matchMedia('(prefers-color-scheme: dark)').matches)
}

export function adjustConfig(config: ClientConfigType): ClientConfigType {
    if (window.innerWidth <= 992) {
        config.nav = 'closed'
    }
    return config
}

export function switchMode(mode: ClientConfigModeType): void {
    const dataMode = isDarkMode(mode) ? 'dark' : 'light'
    document.documentElement.setAttribute('data-mode', dataMode)
    const html = document.querySelector('html')
    if (dataMode === 'dark') {
        html?.classList.add('dark')
        html?.classList.remove('light')
    } else {
        html?.classList.add('light')
        html?.classList.remove('dark')
    }
}
export function switchNav(nav: ClientConfigNavType): void {
    const html = document.querySelector('html')
    if (html) {
        if (window.innerWidth > 992) {
            if (nav && nav === 'open') {
                html.dataset.toggled = ''
            } else {
                html.dataset.toggled = 'icon-overlay-close'
            }
        } else {
            if (nav && nav === 'open') {
                html.dataset.toggled = 'open'
            } else {
                html.dataset.toggled = 'close'
            }
        }
    }
}
