import { isObject } from './isObject.ts'
import { isArray } from './isArray.ts'

export function cloneDeep<T>(source: T): T {
    function cloneObject(source: T): Record<string, unknown> {
        const output: Record<string, unknown> = {}
        for (const key in source) {
            output[key] = cloneDeep(source[key])
        }
        return output
    }

    function cloneArray(source: T): Array<unknown> {
        const output = []
        for (const key in source) {
            output.push(cloneDeep(source[key]))
        }
        return output
    }

    if (isObject(source)) {
        return cloneObject(source) as T
    } else if (isArray(source)) {
        return cloneArray(source) as T
    }
    return source as T
}
