import { isObject } from './isObject.ts'
import { cloneDeep } from './cloneDeep.ts'

/**
 * Merge and deep copy the values of all the enumerable own properties of target object from source object to a new object
 * @param target The target object to get properties from.
 * @param source The source object from which to copy properties.
 * @return A new merged and deep copied object.
 */
export function mergeDeep<T extends object, S extends object | undefined | null>(target: T, source: S): T & S {
    if (source == undefined || !isObject(source)) {
        return { ...target } as T & S
    } else {
        type KEY = keyof object

        if (Object.keys(source).length === 0) {
            return cloneDeep({ ...target, ...source })
        }
        const output = { ...target, ...source }
        if (isObject(target)) {
            Object.keys(source).forEach(key => {
                if (isObject(source[key as KEY]) && key in target && isObject(target[key as KEY])) {
                    output[key as KEY] = mergeDeep((target as object)[key as KEY], (source as object)[key as KEY])
                } else {
                    output[key as KEY] = isObject(source[key as KEY])
                        ? cloneDeep((source as object)[key as KEY])
                        : (source as object)[key as KEY]
                }
            })
        }
        return output
    }
}
