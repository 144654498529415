import { EnvConfigType } from '@/env/EnvConfig.type.ts'

export const defaultEnvConfig: EnvConfigType = {
    apiUrl: 'n/a',
    build: 'n/a'
}

class EnvConfigSingleton {
    private _config: EnvConfigType = defaultEnvConfig
    private _initialized = false

    get config(): EnvConfigType {
        if (!this._initialized) {
            throw new Error('Env config has not been defined yet.')
        }
        return this._config
    }

    set config(value: EnvConfigType) {
        if (!this._initialized) {
            this._config = value
            this._initialized = true
        } else {
            throw new Error('Global config has already been defined')
        }
    }
}

export const envConfig = new EnvConfigSingleton()
export const envConfigUrl = '/env'
