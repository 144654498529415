import { ToastContainer, Zoom } from 'react-toastify'
//import { useClientConfig } from '@/hooks/useClientConfig.tsx'

export default function ToastEmitter() {
    //const { config } = useClientConfig()
    const orientation: string = 'ltr'
    return (
        <>
            <ToastContainer
                limit={5}
                position={orientation == 'rtl' ? 'bottom-left' : 'bottom-right'}
                autoClose={8000}
                hideProgressBar={false}
                newestOnTop
                rtl={orientation == 'rtl'}
                pauseOnFocusLoss={true}
                draggable={false}
                pauseOnHover
                theme={'colored'}
                transition={Zoom}
            />
        </>
    )
}
