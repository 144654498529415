import { TokenDataType } from '@/hooks/ClientAuth.type.ts'

/**
 * Interface for managing client authentication tokens.
 */
interface ClientAuthTokenStoreInterface {
    getAccessToken(): string | null
    getRefreshToken(): string | null
    setTokens(value: TokenDataType): void
    getTokens(): TokenDataType | null
    clearTokens(): void
}

/**
 * Class representing a store for client authentication tokens.
 * Implements a singleton pattern to ensure only one instance exists.
 */
export class ClientAuthTokenStore implements ClientAuthTokenStoreInterface {
    private tokens: TokenDataType | null = null
    // Implementing a singleton
    private static instance: ClientAuthTokenStore
    private constructor() {
        /* singleton */
    }
    public static getInstance(): ClientAuthTokenStore {
        if (!ClientAuthTokenStore.instance) {
            ClientAuthTokenStore.instance = new ClientAuthTokenStore()
        }
        return ClientAuthTokenStore.instance
    }
    getAccessToken(): string | null {
        return this.tokens?.udt ?? null
    }
    getRefreshToken(): string | null {
        return this.tokens?.urt ?? null
    }

    setTokens(value: TokenDataType) {
        this.tokens = value
    }
    getTokens() {
        return this.tokens
    }
    clearTokens(): void {
        this.tokens = null
    }
}

export const clientAuthTokenStore = ClientAuthTokenStore.getInstance()
