import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation, faCircleInfo, faTimes, faTriangleExclamation } from '@fortawesome/pro-light-svg-icons'
import { Id, toast, ToastOptions } from 'react-toastify'
import { JSX } from 'react/jsx-runtime'
import { notificationData, notificationType } from '@/layout/components/toasts/Toast.type.ts'
import { faCircleCheck, faCircleQuestion, IconDefinition } from '@fortawesome/pro-thin-svg-icons'

type ToastContentProps = {
    content: notificationData
    closeToast?: () => void
}

export const ToastContent = (props: ToastContentProps) => {
    const { content, closeToast } = props
    return (
        <>
            <div className='flex items-start '>
                <div className={'me-2 mt-0.5 flex self-start'}>
                    <div className='inline-flex items-center justify-center'>
                        <FontAwesomeIcon icon={content.icon || faCircleQuestion} size={'xl'} />
                    </div>
                </div>
                <div className='flex-grow'>
                    <div className='flex items-center'>
                        <p className='font-semibold grow w-[225px] truncate overflow-hidden mt-0.5'>
                            {content?.headline}
                        </p>
                        <div>
                            <FontAwesomeIcon
                                icon={faTimes}
                                size='xl'
                                fixedWidth
                                className={'opacity-50 hover:opacity-100'}
                                onClick={() => {
                                    if (closeToast) {
                                        closeToast()
                                    }
                                }}
                            />
                        </div>
                    </div>
                    {content?.details ? <p className='text-xs mt-1 line-clamp-3'>{content?.details}</p> : null}
                </div>
            </div>
        </>
    )
}

const toastGenerator = (
    contentProps: JSX.IntrinsicAttributes & ToastContentProps,
    toastProps: ToastOptions<unknown> | undefined
): Id => toast(<ToastContent {...contentProps} />, { ...toastProps })

const defaultIcons: Record<notificationType, IconDefinition> = {
    success: faCircleCheck,
    warning: faTriangleExclamation,
    danger: faCircleExclamation,
    error: faCircleExclamation,
    info: faCircleInfo,
    default: faCircleQuestion
}

export const notification = (type: notificationType = 'default', data: notificationData) => {
    data.icon = data.icon ?? defaultIcons[type]
    toastGenerator({ content: data }, { type: type === 'danger' ? 'error' : type, icon: false })
}
