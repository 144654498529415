import i18next from 'i18next'
import Backend from 'i18next-chained-backend'
import LocalStorageBackend from 'i18next-localstorage-backend' // primary use cache
import HttpBackend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { DateTime } from 'luxon'
import { LOCAL_STORAGE_KEY_I18N_CACHE_PREFIX, LOCAL_STORAGE_KEY_I18N_DETECTED_LOCALE } from '@/i18n/config.ts'
// Disable eslint compoaining about a named export 'use' in i18next

// Config Options:
// https://www.i18next.com/overview/configuration-options
// HTTPBackend: https://github.com/i18next/i18next-http-backend
// LocalStorageBackend: https://github.com/i18next/i18next-localstorage-backend

// Improvements (Auto parsing keys, display missing loc):
// * https://github.com/locize/translation-check
// * http://i18next.github.io/i18next-scanner/
// https://github.com/i18next/i18next-parser

/*
// ----- i18next - common attributes and methods -----
// language
i18next.language;
// Is set to the current detected or set language.

// languages
i18next.languages;
// Is set to an array of language codes that will be used to look up the translation value.
// When the language is set, this array is populated with the new language codes.
// Unless overridden, this array is populated with less-specific versions of that code for fallback purposes, followed by the list of fallback languages

// initialize with fallback languages
i18next.init({
    fallbackLng: ["es", "fr", "en-US", "dev"]
});
// change the language
i18next.changeLanguage("en-US-xx");
// new language and its more generic forms, followed by fallbacks
i18next.languages; // ["en-US-xx", "en-US", "en", "es", "fr", "dev"]
// change the language again
i18next.changeLanguage("de-DE");
// previous language is not retained
i18next.languages; // ["de-DE", "de", "es", "fr", "en-US", "dev"]

// resolvedLanguage
i18next.resolvedLanguage;
// Is set to the current resolved language.
// It can be used as primary used language,
// for example in a language switcher.
*/

export const fallbackLanguage = 'en'
export const supportedLanguages = ['en', 'de']

await i18next

    // Enables the i18next backend
    .use(Backend)

    // Enable automatic language detection
    .use(LanguageDetector)

    // Enables the hook initialization module
    .use(initReactI18next)
    .init({
        fallbackLng: fallbackLanguage,
        supportedLngs: supportedLanguages,
        lowerCaseLng: true,
        saveMissing: false, // Needs backend to support POSTs with missing translation
        debug: false, // true logs errors to console
        keySeparator: '.',
        ns: ['common'], // Namespaces to preload
        defaultNS: 'common',
        detection: {
            lookupLocalStorage: LOCAL_STORAGE_KEY_I18N_DETECTED_LOCALE,
            lookupSessionStorage: LOCAL_STORAGE_KEY_I18N_DETECTED_LOCALE
        },
        backend: {
            backends: [
                LocalStorageBackend, // primary backend
                HttpBackend // fallback backend
            ],
            backendOptions: [
                {
                    // options for primary backend
                    // prefix for stored languages
                    prefix: LOCAL_STORAGE_KEY_I18N_CACHE_PREFIX,

                    // expiration
                    expirationTime: 1117 * 24 * 60 * 60 * 1000,

                    // Version applied to all languages, can be overriden using the option `versions`
                    defaultVersion: undefined,

                    // language versions
                    versions: {},

                    // can be either window.localStorage or window.sessionStorage. Default: window.localStorage
                    store: typeof window !== 'undefined' ? window.localStorage : null
                },
                {
                    // options for secondary backend
                    loadPath: '/locales/{{lng}}/{{ns}}.json',
                    // path to post missing resources, or a function
                    // function(lng, namespace) { return customPath; }
                    // the returned path will interpolate lng, ns if provided like giving a static path
                    addPath: '/locales/add/{{lng}}/{{ns}}'
                }
            ]
        },
        react: {
            useSuspense: true // Needed!
        },
        interpolation: {
            escapeValue: false, // React does this by itself
            formatSeparator: ','
        }
    })
// new usage
// Add formatters based on luxon
if (i18next?.services?.formatter) {
    i18next.services.formatter.add('DATE_HUGE', (value, lng) => {
        return DateTime.fromJSDate(value)
            .setLocale(lng as string)
            .toLocaleString(DateTime.DATE_HUGE)
    })
}

export default i18next
